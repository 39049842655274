.generic-snack-bar-warning {
    background: var(--r1-orange);
    color: var(--r1-white);
    min-height: 40px;
    margin: 15px 16px;
    font-size: var(--r1-font-14);
}

.generic-snack-bar-container {
    display: flex;
    align-items: center;

    .generic-snack-bar-message-container {
        padding: 0 10px 0 0;
    }
}